import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OperationSummaryService } from "app/components/v2/OperationSummary/operation-summary.service";
import { RepurchaseFilterModalComponent } from "app/modules/admin/v2/operation/create/components/repurchase-payables-modal/filter-modal/repurchase-filter-modal.component";
import { RepurchasePayableService } from "app/modules/admin/v2/operation/create/service/repurchase-payable.service";
import { calculateSimpleInterest } from "app/utils/compound-interest";
import { addHours, differenceInDays, format, isSameDay, isSameMonth, isToday, parseISO } from "date-fns";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import {
    UnoperatedPayable,
    UnoperatedPayablesFilter,
    UnoperatedPayablesResponse,
    UnoperatedPayablesSelection,
} from "./interfaces/unoperated-payables";
import { getNextBusinessDay } from "app/utils/next-business-day.util";
import moment, { MomentInput } from "moment";
import { UserService } from "app/core/user/user.service";

@Component({
    templateUrl: "./repurchase-payables.component.html",
    selector: "RepurchasePayables",
    styleUrls: ["./repurchase-payables.component.scss"],
})
export class RepurchasePayablesComponent implements OnInit {
    @Input() assignorId: number;
    @Input() selectionUnoperatedPayables: UnoperatedPayablesSelection;

    @Output() selectEmitter = new EventEmitter<UnoperatedPayablesSelection>();
    @Output() closeEmitter = new EventEmitter<void>();
    @Output() totalRepurchaseEmitter = new EventEmitter<number>();
    @Output() filter = new EventEmitter();

    public allUnoperatedPayablesResponse: UnoperatedPayablesResponse;
    public allUnoperatedPayables: UnoperatedPayable[] = [];
    public unsubscribeAll = new Subject();
    public unoperatedPayablesTableColumns: string[] = [
        "check",
        "payableNumber",
        "payerName",
        "value",
        "repurchaseValue",
        "dueDate",
        "timeUntilDueDate",
        "payableType",
        "lateFee",
        "fine",
    ];
    public repurchaseFeesForm: FormGroup;
    public selectedPayables: UnoperatedPayable[] = [];
    public page = 1;
    public totalPages = 0;
    public feeFineSum = 0;
    public payablesSum = 0;
    public isLoading: boolean;
    public filters: UnoperatedPayablesFilter = {
        payableNumber: "",
        payerName: "",
        payableType: "",
    };
    public isFilterApplied = false;
    public errors = [];
    public noHasBankmeUser = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly snack: MatSnackBar,
        private readonly operationSummaryService: OperationSummaryService,
        private readonly repurchasePayableService: RepurchasePayableService,
        private readonly dialog: MatDialog,
        private readonly userService: UserService,
    ) {}

    async ngOnInit(): Promise<void> {
        const user = this.userService.user$.user;

        this.repurchaseFeesForm = this.formBuilder.group({
            lateFee: [0, [Validators.required, Validators.max(100)]],
            fine: [0, [Validators.required, Validators.max(100)]],
        });

        this.repurchaseFeesForm.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribeAll)).subscribe(() => {
            this.doTheMath();
        });
        this.repurchasePayableService.getUnoperatedPayables.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
            this.allUnoperatedPayablesResponse = data;
            this.allUnoperatedPayables = this.allUnoperatedPayablesResponse.data.map((payable) => {
                const minimumRepurchaseValue = this.calculateFine(payable) + this.calculateLateFee(payable);
                return {
                    ...payable,
                    repurchaseValue: payable.value,
                    minimumRepurchaseValue,
                };
            });
            this.allUnoperatedPayables.forEach((payable) => {
                this.errors[payable.id] = {
                    hasError: false,
                    message: "",
                };
            });
            this.totalPages = Math.ceil(this.allUnoperatedPayablesResponse.meta.total / 10) || 1;
        });

        this.isLoading = true;

        this.allUnoperatedPayablesResponse = await this.repurchasePayableService.listUnoperatedPayables(
            this.assignorId,
            {
                page: 1,
                take: 10,
            },
        );
        this.allUnoperatedPayables = this.allUnoperatedPayablesResponse.data.map((payable) => {
            const minimumRepurchaseValue = this.calculateFine(payable) + this.calculateLateFee(payable);
            return {
                ...payable,
                repurchaseValue: payable.value,
                minimumRepurchaseValue,
            };
        });

        this.allUnoperatedPayables.forEach((payable) => {
            this.errors[payable.id] = {
                hasError: false,
                message: "",
            };
        });
        this.totalPages = Math.ceil(this.allUnoperatedPayablesResponse.meta.total / 10) || 1;

        // Caso feche e abra o componente
        this.selectedPayables = this.selectionUnoperatedPayables.payables || [];
        this.repurchaseFeesForm.controls.lateFee.setValue(
            this.selectionUnoperatedPayables.lateFeePercentage * 100 || 0,
        );
        this.repurchaseFeesForm.controls.fine.setValue(this.selectionUnoperatedPayables.finePercentage * 100 || 0);
        this.doTheMath();

        this.isLoading = false;
    }

    formatTimeUntilDueDate(date: string) {
        const dueDate = addHours(new Date(date), 3);
        const today = String(new Date().toISOString()).split("T")[0];
        const diffDays = differenceInDays(new Date(dueDate), new Date(today));
        const sameDay = isToday(dueDate);

        const diffDaysPositive = Math.abs(diffDays);
        const statusMap = {
            overdue: `Vencido há ${diffDaysPositive + 1} dia(s)`,
            dueToday: "Vence hoje",
            dueSoon: `Vence em ${diffDays} dia(s)`,
        };

        let statusKey = sameDay ? "dueToday" : "dueSoon";
        statusKey = dueDate < new Date(today) ? "overdue" : statusKey;

        return statusMap[statusKey];
    }

    trackByFn(index: number, item: { id: number | null }): number {
        return item.id || index;
    }

    doTheMath() {
        this.calculateTotalRepurchase();
        this.calculateTotalFineFee();
    }

    calculateTotalRepurchase() {
        const payablesSum = this.selectedPayables.reduce((c, next) => {
            return c + Number(next.repurchaseValue) + this.calculateFine(next) + this.calculateLateFee(next);
        }, 0);

        this.payablesSum = payablesSum;
        this.totalRepurchaseEmitter.emit(this.payablesSum);
    }

    calculateTotalFineFee() {
        const totalFine = this.selectedPayables.reduce((total, payable) => total + this.calculateFine(payable), 0);
        const totalLateFee = this.selectedPayables.reduce(
            (total, payable) => total + this.calculateLateFee(payable),
            0,
        );
        this.feeFineSum = totalFine + totalLateFee;

        this.selectedPayables = this.selectedPayables.map((payable) => {
            const minimumRepurchaseValue = this.calculateFine(payable) + this.calculateLateFee(payable);
            return {
                ...payable,
                minimumRepurchaseValue,
            };
        });
    }

    selectPayable(payable: UnoperatedPayable) {
        const index = this.selectedPayables.findIndex((p) => p.id === payable.id);
        const type = index === -1 ? "push" : "splice";
        const method = {
            push: () => this.selectedPayables.push(payable),
            splice: () => this.selectedPayables.splice(index, 1),
        };
        if (!method[type]) return;

        method[type]();

        this.doTheMath();
    }

    calculateFine(payable: UnoperatedPayable) {
        if (this.checkDateIsPast(payable.dueDate)) {
            return 0;
        }

        return (payable.value * this.repurchaseFeesForm.controls.fine.value) / 100;
    }

    checkMonthOrDay(payable: UnoperatedPayable) {
        const dueDate = parseISO(payable.dueDate.slice(0, -1));
        return isSameMonth(new Date(), dueDate) || isSameDay(new Date(), dueDate);
    }

    calculateLateFee(payable: UnoperatedPayable) {
        if (this.checkDateIsPast(payable.dueDate)) return 0;
        const lateFee = this.repurchaseFeesForm.controls.lateFee.value / 100;
        const daysOverdue = this.calculateDays(payable.dueDate).days;
        return calculateSimpleInterest({
            value: payable.value,
            percentage: lateFee,
            days: daysOverdue,
        });
    }

    close() {
        this.closeEmitter.emit();
    }

    select() {
        if (!this.selectedPayables.length) {
            return this.informError("Selecione ao menos um recebível para recompra");
        }

        if (!this.repurchaseFeesForm.valid) {
            return this.informError("É necessário informar o valor de Mora e Multa");
        }

        this.doTheMath();

        const { fine, lateFee } = this.repurchaseFeesForm.controls;

        this.selectEmitter.emit({
            finePercentage: fine.value / 100,
            lateFeePercentage: lateFee.value / 100,
            payables: this.selectedPayables,
            lateFeeAndFineSum: this.feeFineSum,
            repurchaseValue: this.payablesSum,
        });

        this.operationSummaryService.updateTotalRepurchase(this.payablesSum + this.feeFineSum);

        this.closeEmitter.emit();
    }

    informError(message: string) {
        this.snack.open(message, "x", {
            duration: 3000,
        });
    }

    nextPage() {
        if (this.page + 1 > this.totalPages) return;
        this.loadMoreEmitter(this.page + 1);
        this.page = this.page + 1;
    }

    previousPage() {
        if (this.page === 1) return;
        this.loadMoreEmitter(this.page - 1);
        this.page = this.page - 1;
    }

    firstPage() {
        if (this.page === 1) return;
        this.loadMoreEmitter(1);
        this.page = 1;
    }

    lastPage() {
        if (this.page === this.totalPages) return;
        this.loadMoreEmitter(this.totalPages);
        this.page = this.totalPages;
    }

    loadMoreEmitter(page: number) {
        this.allUnoperatedPayablesResponse.data = [];

        this.repurchasePayableService
            .listUnoperatedPayables(this.assignorId, {
                page,
                take: 10,
                ...this.filters,
            })
            .then((res: UnoperatedPayablesResponse) => {
                this.allUnoperatedPayablesResponse = res;
                this.allUnoperatedPayables = res.data.map((payable) => {
                    const minimumRepurchaseValue = this.calculateFine(payable) + this.calculateLateFee(payable);
                    return {
                        ...payable,
                        repurchaseValue: payable.value,
                        minimumRepurchaseValue,
                    };
                });

                this.allUnoperatedPayables.forEach((payable) => {
                    this.errors[payable.id] = {
                        hasError: false,
                        message: "",
                    };
                });
            });
    }

    checkIfSelected(payable: UnoperatedPayable) {
        return this.selectedPayables.some((p) => p.id === payable.id);
    }

    formatDateIso(date: string) {
        const newDate = new Date(date.slice(0, -1));
        return format(newDate, "dd/MM/yyyy");
    }

    checkDateIsPast(date: string) {
        return new Date(date).getTime() > new Date().getTime();
    }

    openFilterModal() {
        const dialogRef = this.dialog.open(RepurchaseFilterModalComponent, {
            data: {
                payableNumber: this.filters.payableNumber,
                payerName: this.filters.payerName,
                payableType: this.filters.payableType,
            },
        });

        dialogRef.componentInstance.filterEmitter.pipe(takeUntil(this.unsubscribeAll)).subscribe((data) => {
            this.filters = data;
            this.isFilterApplied = true;
        });
    }

    async resetFilters() {
        this.filters = {
            payableNumber: "",
            payerName: "",
            payableType: "",
        };
        this.isLoading = true;
        this.allUnoperatedPayablesResponse.data = [];

        const response = await this.repurchasePayableService.listUnoperatedPayables(this.assignorId, {
            page: 1,
            take: 10,
            ...this.filters,
        });

        this.allUnoperatedPayablesResponse = response;
        this.allUnoperatedPayables = response.data.map((payable) => {
            const minimumRepurchaseValue = this.calculateFine(payable) + this.calculateLateFee(payable);
            return {
                ...payable,
                repurchaseValue: payable.value,
                minimumRepurchaseValue,
            };
        });
        this.allUnoperatedPayables.forEach((payable) => {
            this.errors[payable.id] = {
                hasError: false,
                message: "",
            };
        });
        this.isFilterApplied = false;

        this.isLoading = false;
    }

    onRepurchaseValueChange(input, currentPayable) {
        this.selectedPayables = this.selectedPayables.map((payable) => {
            if (
                payable.id === Number(currentPayable.id) &&
                input >= payable.minimumRepurchaseValue &&
                input <= payable.value
            ) {
                this.errors[payable.id].hasError = input < payable.minimumRepurchaseValue || input > payable.value;
                this.errors[payable.id].message = `O máximo é ${payable.minimumRepurchaseValue + payable.value}`;
            }
            const result = {
                ...payable,
                repurchaseValue: this.noHasBankmeUser ? payable.value : payable.repurchaseValue,
            };

            if (payable.id === Number(currentPayable.id)) {
                result.repurchaseValue = Number(input);
            }

            return result;
        });
        return this.doTheMath();
    }

    calculateDays(date: Date | string) {
        const dueDate = getNextBusinessDay(new Date(date));
        const diffDays = moment().diff(dueDate as MomentInput, "days");

        const signal = diffDays < 0 ? "+" : "-";
        const absDiffDays = Math.abs(diffDays);

        return {
            signal,
            days: absDiffDays,
            text: `${signal === "-" ? "vencido há" : "vence em"} ${absDiffDays} dia${absDiffDays > 1 ? "s" : ""}`,
        };
    }
}
